import axios from "axios";
import { BASE_URL } from "../../config";
const client = axios.create({
    baseURL: BASE_URL
});

//Interceptor adds bearer token to each request
client.interceptors.request.use(function (config) {
    const { accessToken } = JSON.parse(localStorage.getItem("store")) || {};
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    return config;
}, function (error) {
    return Promise.reject(error);
})

export default client;