import styled from "@emotion/styled";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import IMAGES from "../../assets/images/images";
import { useAppAuth } from "../../contexts/AuthProvider";


const SocialButton = styled(Button)(() => ({
  height: "44px",
  whiteSpace: 'nowrap',
  fontWeight: '400',
  width: '100%'
}));

const GoogleAuth = ({ assessmentId }) => {
  const appAuth = useAppAuth()
  const GoogleLoginHandler = useGoogleLogin({
    onSuccess: async (repo) => {
      try {
        await appAuth.authenticate({ 
          provider: 'Google', 
          payload: { 
            accessToken: repo?.access_token,
            ...(assessmentId && { assessmentId })
          }
        });
      } catch (err) {
        toast(err?.data?.message, { type: "error" });
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <SocialButton
      color={"secondary"}
      variant={"outlined"}
      className={`btn-lg w-full text-dark`}
      onClick={GoogleLoginHandler}
    >
      <img className={`img-fluid`} src={IMAGES.GoogleLogo} alt="google" style={{ marginRight: '5px' }} />
      Continue with Google
    </SocialButton>
  );
}

export default GoogleAuth;
