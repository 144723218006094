export const BASE_URL = process.env.REACT_APP_API_URL;
export const screenshotType = 'image/png';
export const camShotInterval = 1000*10;
export const appName = 'Questa';
export const appOrigin = "https://questa.in"

export const GOOGLE_MAP = {
    API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY
}

export const GOOGLE_OAUTH = {
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
}

export const LINKED_IN_OAUTH = {
    SCOPE: 'openid email profile',
    CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    REDIRECT_URI: process.env.REACT_APP_LINKEDIN_REDIRECT_URL
}