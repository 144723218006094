import * as React from 'react'
import { useAppAuth } from '../contexts/AuthProvider'
import PageLoader from '../Components/Loader/PageLoader'
import AuthenticateCandidate from '../pages/AuthenticateCandidate'

const AuthGuard = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAppAuth()
  
  if(!isInitialized) return <PageLoader />

  if(!isAuthenticated) return <AuthenticateCandidate />

  return children
}

export default AuthGuard