import axios from "axios";
// import { toast } from "react-hot-toast";

export const uploadImageToAWS = async (preSignedUrl, contentType, blob) => {
    // console.log('sendCamShot => ', contentType)
    // toast.success(contentType)
    // return Promise.resolve(contentType)
    const config = {
        method: 'put',
        url: preSignedUrl,
        headers: {
            'Content-Type': contentType
        },
        data: blob
    };
    await axios(config);
} 