import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../config";

const axiosInstance = axios.create({ baseURL: BASE_URL  })

//Interceptor adds bearer token to each request
axiosInstance.interceptors.request.use(function (config) {
    // const accessToken = localStorage.getItem("authToken") || ''
    // config.headers.Authorization = `Bearer ${accessToken}`
    const authToken = Cookies.get('authToken') || ''
    // console.log({ authToken })
    config.headers.Authorization = `Bearer ${authToken}`
    return config;
}, function (error) {
    return Promise.reject(error);
})

axiosInstance.interceptors.response.use(function(response){
    return response.data?.data
}, function(error){
    return Promise.reject(error)
})

export default axiosInstance