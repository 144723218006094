import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

const PageError = ({ message = 'Something went wrong' }) => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: 'rgba(0, 0, 0, 0.5)',
        position: "fixed",
        left: '0',
        top:0,
        zIndex: 1080
      }}
    >
      <ErrorIcon color='danger' />
      {!!message && (
        <Typography pl={3} sx={{ color: 'white' }}>{message}</Typography>
      )}
    </Box>
  );
};

export default PageError;

