import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { flowControlSyncToDB } from "./middleware/flowControlSyncToDB";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
      getDefaultMiddleware
    }).concat(flowControlSyncToDB),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;


