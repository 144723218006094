import { createSlice } from "@reduxjs/toolkit";

const initState = {
  __initialized: false,
  __data: null,
  __error: null
}

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initState,
  reducers: {
    setAssessmentEmpty: () => {
      return initState;
    },
    setAssessment: (state, action) => {
      const { payload } = action;
      state.__initialized = true
      state.__data = payload.data
      state.__error = payload.error
    },
  },
});