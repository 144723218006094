import { candidate as candidateService } from '../../services'

const modifyStateMap = {
    'flowControl/markAssessmentStepComplete': (state, action) => {
        const stepKey = action.payload
        state.assessment.progress[stepKey].completed = true
        return state
    },
    'flowControl/markTestStepComplete': (state, action) => {
        const stepKey = action.payload
        state.currentTest.progress[stepKey].completed = true
        return state
    },
    'flowControl/markTestComplete': (state) => {
        state.assessment.tests.currTestIndex += 1
        state.currentTest.index += 1
        state.currentTest.__initialized = false
        return state
    },
    'flowControl/markQuestionComplete': (state, action) => {
        state.currentTest.currAQIndex += 1
        state.currentTest.isSaving = false
        return state
    }
}

export const flowControlSyncToDB = (store) => (next) => async(action) => {
    console.log(`______________Action: ${action.type}________________`)
    const state = store.getState().flowControl
    if(modifyStateMap[action.type]){
        const _state = JSON.parse(JSON.stringify(state))
        // console.log(_state)
        const payload = modifyStateMap[action.type](_state, action)
        await candidateService.updateFlowControl(payload).catch(err => console.log(err))
    }
    next(action)
}