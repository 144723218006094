import { Box, CircularProgress } from '@mui/material'
import * as React from 'react'

const PageLoader = () => {
  return (
    <Box sx={{ position: 'relative', height: '100vh', width: '100vw' }} >
        <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />
    </Box>
  )
}

export default PageLoader